import React from "react"

import Layout from "../../components/en/Layout"
import SEO from "../../components/en/SEO"
import Breadcrumbs from "../../components/en/Breadcrumbs"

import "./Pages.css"

const TermosDeServico = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Terms of Service"
        canonical={`${process.env.GATSBY_HOST_URL}/en/terms-of-service`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/termos-de-servico`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Terms of Service"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>Terms of Service</h1>
          </div>

          <div className="content-static-pages">
            <strong>1. ACCEPTANCE</strong>

            <p>
              This is a contract signed between you, hereinafter referred to as
              user, and Taha Brasil Tecnologia e Marketing LTDA, registered with
              CNPJ nº 35.950.269 / 0001-13, with address Rua Marechal Floriano
              nº 654, Sala 607, Centro , CEP 35010-140, Governador Valadares -
              MG. This “Application Use Term” governs the use of all
              applications made available free of charge by Taha Brasil
              Tecnologia e Marketing LTDA, whether for mobile devices (Android,
              IOS, Windows Mobile), servers, personal computers (desktops) or
              web services. If you do not agree with these terms do not use this
              application. You further acknowledge that you have analyzed and
              accepted the conditions of use. We recommend careful reading, as
              the use of this application means that you have accepted all the
              terms and agree to comply with them. If you, the user, are a minor
              or declared incapable in any way, you will need the permission of
              your parents or guardians, according to the provisions of the
              Civil Code, who must also agree with these same terms and
              conditions.
            </p>

            <strong>2. LIMITED LICENSE</strong>

            <p>
              You have received a limited, non-transferable, non-exclusive,
              royalty-free and revocable license to download, install, run and
              use this “NIZAM” application on your device. You acknowledge and
              agree that Taha Brasil Tecnologia e Marketing LTDA grants the user
              an exclusive license for use and therefore does not transfer the
              rights to the product. The application should only be used by you,
              the user. The sale, transfer, modification, reverse engineering or
              distribution as well as the copying of texts, images or any parts
              contained therein is expressly prohibited.
            </p>

            <strong>3. CHANGES, MODIFICATIONS AND TERMINATION</strong>

            <p>
              Taha Brasil Tecnologia e Marketing LTDA, reserves the right, at
              any time, to modify these terms, including, removing or changing
              any of its clauses. Such changes will take effect immediately.
              After publishing such changes, by continuing to use the
              application, you will have accepted and agreed to comply with the
              modified terms. Taha Brasil Tecnologia e Marketing LTDA may, from
              time to time, modify or discontinue (temporarily or permanently)
              the distribution or updating of this application. Taha Brasil
              Tecnologia e Marketing LTDA is not obligated to provide any
              support services for this application. The user will not be liable
              to Taha Brasil Tecnologia e Marketing LTDA its directors,
              executives, employees, affiliates, agents, contractors or
              licensors for any modifications, suspensions or discontinuity of
              the application.
            </p>

            <strong>CONSENT TO DATA COLLECTION AND USE</strong>

            <p>
              You agree that Taha Brasil Tecnologia e Marketing LTDA may collect
              and use technical data from your device such as specifications,
              configurations, operating system versions, type of internet
              connection and the like, all in accordance with current
              legislation and its subsequent amendments.
            </p>

            <strong>
              DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITY
            </strong>

            <p>
              This application will be in continuous development and may contain
              errors and, therefore, use is provided "as is" and at the risk of
              the end user. To the maximum extent permitted by applicable law,
              Taha Brasil Tecnologia e Marketing LTDA and its suppliers disclaim
              any express or implied warranties and conditions including,
              without limitation, warranties of merchantability, fitness for a
              specific purpose, ownership and non-infringement with respect to
              respect to the application and any of its components or to the
              provision or not of support services. Taha Brasil Tecnologia e
              Marketing LTDA does not guarantee that the operation of this
              application is continuous and without defects.
            </p>

            <p>
              Except as set forth in this document, there are no other
              warranties, conditions or promises to applications, express or
              implied, and all such warranties, conditions and promises may be
              excluded in accordance with what is permitted by law without
              prejudice to Taha Brasil Tecnologia e Marketing LTDA and its
              collaborators.
            </p>

            <p>
              I. Taha Brasil Tecnologia e Marketing LTDA does not guarantee,
              represent or warrant that the use of this application will be
              uninterrupted or error-free and you agree that Taha Brasil
              Tecnologia e Marketing LTDA may remove for indefinite periods or
              cancel this application at any time without you to be warned.
            </p>

            <p>
              II. Taha Brasil Tecnologia e Marketing LTDA does not guarantee,
              declare or guarantee that this application is free of loss,
              interruption, attack, virus, interference, piracy or other
              security invasion and is exempt from any responsibility in
              relation to these issues. You are responsible for backing up your
              own device.
            </p>

            <p>
              III. Under no circumstances will Taha Brasil Tecnologia e
              Marketing LTDA, as well as its directors, executives, employees,
              affiliates, agents, contractors or licensors be liable for losses
              or damages caused by the use of the application.
            </p>

            <p>
              IV. The contents transmitted by the applications those such as
              articles, images, texts, sources, translations and the like are
              the responsibility of their creators. Taha Brasil Tecnologia e
              Marketing LTDA disclaims any direct or indirect responsibility for
              these contents and access is optional for the user.
            </p>

            <p>Governador Valadares, August 11, 2020.</p>

            <strong style={{ display: "block", textAlign: "center" }}>
              Taha Brasil Tecnologia e Marketing LTDA
            </strong>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermosDeServico
